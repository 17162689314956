<template>
    <div class="modal fade modal-fullscreen" id="checkListModal" tabindex="-1" aria-labelledby="Чек-лист" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content p-3 rounded-1">
                <div class="modal-header border-0 p-1">
                    <div class=" d-flex align-items-center container-fluid p-1 mb-1">
                        <span class="modal-title fs-2 fw-semi font-semi me-auto" style="max-width: 90%;">Чек-лист</span>
                        <button type="button" class="d-flex ms-auto btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                </div>
                <div class="modal-body border-0 p-1">
                    <div v-if="!this.isEmpty(this.stages)" class="accordion" id="checkList">
						<div v-for="(stage, id, index) in this.stages"
							 :key="index"
							 :data-id="id"
							 :data-num="index"
							 class="accordion-item">
							<div class="accordion-header"
								 :id="'checkList-heading_' + stage.id">
								<button class="crm-accordion-btn border-top border-brd-primary accordion-button"
										:class="(index === 0) ? '' : 'collapsed'"
										type="button"
										data-bs-toggle="collapse"
										:aria-expanded="(index === 0) ? 'true' : 'false'"
										:data-bs-target="'#checkList-collapse_' + stage.id"
										:aria-controls="'checkList-collapse_' + stage.id"
										v-text="stage.name" />
							</div>
							<div :id="'checkList-collapse_' + stage.id"
								 class="accordion-collapse collapse"
								 :class="(index === 0) ? 'show' : ''"
								 :aria-labelledby="'checkList-heading_' + stage.id">
								<div v-if="!this.isEmpty(stage.files_list) || !this.isEmpty(stage.fields_list)" class="d-flex flex-column py-3">
									<!-- Файлы для скачивания -->
									<div v-if="!this.isEmpty(stage.files_list)">
										<label class="mb-3 text-dark-gray">Файлы для скачивания:</label>
										<ul class="list-unstyled d-flex flex-column">
											<li v-for="(file, index) of stage.files_list"
												:key="index"
												class="list-item">
												<a :href="file.path"
												   class="d-inline-flex align-items-center mb-2 link border-bottom-0 text-decoration-none mw-90"
												   :download="file.file_name + file.file_type">
													<img v-if="this.inArray(file.file_type, ['doc', 'docx'])" :src="require('@/assets/icons/file-word.svg')" alt="Документ Word" width="18" height="18" class="me-2">
													<img v-else-if="this.inArray(file.file_type, ['xls', 'xlsx'])" :src="require('@/assets/icons/file-excel.svg')" alt="Документ Excel" width="18" height="18" class="me-2">
													<img v-else-if="this.inArray(file.file_type, ['ppt', 'pptx'])" :src="require('@/assets/icons/file-powerpoint.svg')" alt="Документ PowerPoint" width="18" height="18" class="me-2">
													<img v-else-if="this.inArray(file.file_type, ['pdf'])" :src="require('@/assets/icons/file-pdf.svg')" alt="PDF-документ" width="18" height="18" class="me-2">
													<img v-else-if="this.inArray(file.file_type, ['jpg','jpeg','png','bmp','tiff','gif'])" :src="require('@/assets/icons/file-image.svg')" alt="Изображение" width="18" height="18" class="me-2">
													<img v-else :src="require('@/assets/icons/blank-icon.svg')" alt="Файл" width="18" height="18" class="me-2">
													<span class="me-10 text-nowrap-mask pb-1 pe-4">
												<span class="fs-4 link link-dashed">{{ file.file_name }}</span>
											</span>
												</a>
											</li>
										</ul>
									</div>
									<!-- /Файлы для скачивания -->
									<!-- Список полей для заполнения при завершении этапа -->
									<div v-if="!this.isEmpty(stage.fields_list)">
										<div class="needs-validation">
											<div v-for="(field, index) of stage.fields_list" :key="index">
												<div v-if="field.type == 'text'" class="col mb-3">
													<InputText :inputId="field.name"
															   :inputName="field.name"
															   :ref="field.name"
															   :inputLabel="field.label + ':'"
															   :inputValue="field.value"
															   :inputRequired="field.is_required"
															   inputLabelClass="text-font-secondary mb-3"
															   inputClass="rounded-3 p-2"
															   inputWrapClass="mb-20"
															   @setValue="(value) => this.setAndValidate(stage.id, field.type, field.name, value)" />
												</div>
												<div v-if="field.type == 'textarea'" class="col mb-3">
													<TextArea :inputId="field.name"
															  :inputName="field.name"
															  :ref="field.name"
															  :inputLabel="field.label + ':'"
															  :inputValue="field.value"
															  :inputRequired="field.is_required"
															  inputLabelClass="text-font-secondary mb-3"
															  inputClass="rounded-3 p-2"
															  inputWrapClass="mb-20"
															  @setValue="(value) => this.setAndValidate(stage.id, field.type, field.name, value)" />
												</div>
												<div v-if="field.type == 'datetime'" class="col mb-3">
													<DateTimePicker :inputId="field.name"
																	:inputName="field.name"
																	:ref="field.name"
																	inputLabelClass="text-font-secondary mb-3"
																	inputClass="rounded-3 p-2"
																	inputWrapClass="mb-20"
																	:inputLabel="field.label + ':'"
																	:inputValue="(field.value) ? field.value : false"
																	:inputRequired="field.is_required"
																	displayFormat="DD.MM.YYYY"
																	@setValue="(value) => this.setAndValidate(stage.id, field.type, field.name, value)" />
												</div>
												<div v-if="field.type == 'file'" class="col mb-3">
													<InputFile :inputId="field.name"
															   :inputName="field.name"
															   :ref="field.name"
															   :inputLabel="field.label + ':'"
															   :inputValue="field.value"
															   :inputRequired="field.is_required"
															   inputLabelClass="text-font-secondary mb-3"
															   inputClass="rounded-3 p-2"
															   inputWrapClass="mb-20"
															   inputRemove="true"
															   inputMultiple="true"
															   @removeValue="(value) => this.removeFile(stage.id, field.name, value)"
															   @setValue="(value) => this.uploadFile(stage.id, field.name, value)" />
												</div>
												<div v-if="field.type == 'select'" class="col mb-3">
													<InputSelect :inputId="field.name"
																 :inputName="field.name"
																 :ref="field.name"
																 :inputLabel="field.label + ':'"
																 :inputValue="field.value"
																 :inputOptions="field.select"
																 :inputRequired="field.is_required"
																 inputLabelClass="text-font-secondary mb-3"
																 inputClass="rounded-3 p-2"
																 inputUsePlaceholder="true"
																 inputWrapClass="mb-20"
																 @setValue="(value) => this.setAndValidate(stage.id, field.type, field.name, value)" />
												</div>
												<div v-if="field.type == 'checkbox'" class="col mb-3">
													<span class="d-block mb-3 text-secondary" style="max-width: 350px;">
														{{ field.label }}:
														<sup v-if="field.is_required" class="text-danger">*</sup>
													</span>
													<ul class="d-flex flex-wrap m-0 p-0 mb-10 list-unstyled" >
														<li class="mb-1 me-auto" style="width: 50%;">
															<button class="p-10 d-flex align-items-center btn btn-outline dropdown-item rounded-3"
																	@click="this.newTaskEvent(field.name, 'call', stage.id)">
																<div class="d-flex align-items-center justify-content-center bg-primary rounded-circle me-10" style="width:34px; height: 34px;">
																	<img :src="require('@/assets/icons/phone-icon.svg')" alt="Звонок" width="16" height="16" class="d-block">
																</div>
																<span class="font-semi fw-semi">Звонок</span>
															</button>
														</li>
														<li class="mb-1 me-auto" style="width: 50%;">
															<button class="p-10 d-flex align-items-center btn btn-outline dropdown-item rounded-3"
																	@click="this.newTaskEvent(field.name, 'meet', stage.id)">
																<div class="d-flex align-items-center justify-content-center bg-pink rounded-circle me-10" style="width:34px; height: 34px;">
																	<img :src="require('@/assets/icons/bag-icon.svg')" alt="Встреча" width="16" height="16" class="d-block">
																</div>
																<span class="font-semi fw-semi">Встреча</span>
															</button>
														</li>
														<li class="mb-1 me-auto" style="width: 50%;">
															<button class="p-10 d-flex align-items-center btn btn-outline dropdown-item rounded-3"
																	@click="this.newTaskEvent(field.name, 'show', stage.id)">
																<div class="d-flex align-items-center justify-content-center bg-warning rounded-circle me-10" style="width:34px; height: 34px;">
																	<img :src="require('@/assets/icons/eye-icon.svg')" alt="Показ" width="16" height="16" class="d-block">
																</div>
																<span class="font-semi fw-semi">Показ</span>
															</button>
														</li>
														<li class="mb-1 me-auto" style="width: 50%;">
															<button class="p-10 d-flex align-items-center btn btn-outline dropdown-item rounded-3"
																	@click="this.newTaskEvent(field.name, 'deal', stage.id)">
																<div class="d-flex align-items-center justify-content-center bg-raspberry rounded-circle me-10" style="width:34px; height: 34px;">
																	<img :src="require('@/assets/icons/deal-icon.svg')" alt="Сделка" width="16" height="16" class="d-block">
																</div>
																<span class="font-semi fw-semi">Сделка</span>
															</button>
														</li>
													</ul>
													<div v-if="!this.isEmpty(field.value)" class="col col-12">
														<ul class="list-unstyled list-group mb-3">
															<li v-for="(task, index) in field.value" :key="index" class="list-item mb-1 fs-4">
																<span v-if="task.type == 'call'" class="fw-bold text-success">{{ task.name }} {{ task.date }}</span>
																<span v-else-if="task.type == 'show' || task.type == 'showing'" class="fw-bold text-warning">{{ task.name }} {{ task.date }}</span>
																<span v-else-if="task.type == 'meet' || task.type == 'meeting'" class="fw-bold text-pink">{{ task.name }} {{ task.date }}</span>
																<span v-else-if="task.type == 'deal'" class="fw-bold text-danger">{{ task.name }} {{ task.date }}</span>
																<span v-else class="fw-bold text-warning">{{ task.name }} {{ task.date }}</span>
															</li>
														</ul>
													</div>
												</div>
												<div v-if="field.type == 'number'" class="col mb-3">
													<InputText :inputId="field.name"
															   :inputName="field.name"
															   :ref="field.name"
															   :inputLabel="field.label + ':'"
															   :inputValue="field.value"
															   :inputRequired="field.is_required"
															   inputLabelClass="text-font-secondary mb-3"
															   inputClass="rounded-3 p-2"
															   inputWrapClass="mb-20"
															   @setValue="(value) => this.setAndValidate(stage.id, field.type, field.name, value)" />
												</div>
											</div>
										</div>
									</div>
									<!-- /Список полей для заполнения при завершении этапа -->
								</div>
								<div v-else class="d-flex align-items-center py-3">
									<img :src="require('@/assets/icons/error-icon.svg')" alt="Этап воронки" width="18" height="18" class="me-10">
									<span class="d-block">Нет условий</span>
								</div>
							</div>
						</div>
					</div>
					<div v-else class="d-flex flex-column">
						<Loader />
					</div>
                </div>

				<div class="modal-footer border-0 p-1">
					<button type="button"
							class="btn btn-primary text-white col-12 rounded-3 mt-3 py-2 fs-3 align-content-center"
							:disabled="this.isEmpty(this.stages)"
							data-bs-dismiss="modal">
						Сохранить
					</button>
				</div>
            </div>
        </div>
    </div>

	<NewTaskModal ref="checkListTaskModal"
				  modalId="checkListTask"
				  :eventSection="this.new_task_section"
				  :eventType="this.new_task_event_type"
				  :clientId="this.new_task_client_id"
				  :objectId="this.new_task_object_id"
				  :requisitionId="this.new_task_requisition_id"
				  :fieldName="this.new_task_field_name"
				  :stageId="this.new_task_stage_id"
				  @newTasksClosed="this.newEventReset()"
				  @updateTasksList="(list) => this.updateTasksList(list)" />

	<ResultsModal id="addEditCheckList"
				  :state="this.resultsModalState.state"
				  :title="this.resultsModalState.title"
				  :message="this.resultsModalState.message" />

</template>

<script>

import CommonService from "@/services/CommonService";
import api from "@/api";
import ResultsModal from "@/components/modals/ResultsModal";
import InputText from "@/components/inputs/InputText";
import TextArea from "@/components/inputs/TextArea";
import DateTimePicker from "@/components/inputs/DateTimePicker";
import InputFile from "@/components/inputs/InputFile";
import InputSelect from "@/components/inputs/InputSelect";
import Loader from "@/components/common/Loader";
import NewTaskModal from "@/components/modals/NewTaskModal";

export default {
	name: 'СheckListModal',
	props: {

	},
	components: {
		NewTaskModal,
		Loader,
		InputSelect,
		InputFile,
		DateTimePicker,
		TextArea,
		InputText,
		ResultsModal

	},
	data() {
		return {
			section: null,
			funnel_id: null,
			client_id: null,
			object_id: null,
			requisition_id: null,
			stages: null,
			checklist: [],
			resultsModalState: {
				state: '',
				title: '',
				message: ''
			},
			new_task_section: null,
			new_task_event_type: null,
			new_task_client_id: null,
			new_task_object_id: null,
			new_task_requisition_id: null,
			new_task_field_name: null,
			new_task_stage_id: null,
			is_form_changed: false,
		}
	},
	methods: {
		isEmpty(data) {
			return CommonService.isEmpty(data);
		},
		inArray(needle, haystack) {
			return CommonService.inArray(needle, haystack);
		},
		formatDate(format, datetime) {
			return CommonService.formatDateTime(datetime, 'ru', format);
		},
		formatPrice(value, currency, sign) {
			return CommonService.formatPrice(value, currency, sign);
		},
		sortArray(data) {
			return CommonService.sortArray(data);
		},
		formatDateTime(datetime, locale, format) {
			return CommonService.formatDateTime(datetime, locale, format);
		},
		hideAndGoToRoute(path) {
			let modal = CommonService.getModal('historyTasksModal');
			if (modal) {
				modal.hide();

				if (!this.isEmpty(this.item.id))
					path = path + '#item_' + this.item.id.toString();

				this.$router.push(path);
			}
		},
		removeFile(stage_id, prop_name, file) {

			let section = this.section;
			let params = {};

			if (typeof section !== "undefined") {

				params.section = section;

				if (this.client_id)
					params.client_id = this.client_id;

				if (this.requisition_id)
					params.requisition_id = this.requisition_id;

				if (this.object_id)
					params.object_id = this.object_id;

			}

			if (typeof file !== "undefined")
				params.filename = file.name;

			console.debug('removeFile()::axios', {
				params: params
			});

			let _this = this;
			api.delete('/common/file', {
				params: params
			}).then((response) => {

				if (process.env.NODE_ENV == "development")
					console.debug('removeFile()::axios', {
						response: response.data
					});

				if (response.status == 200 && response.data.success) {
					if (!_this.isEmpty(_this.checklist[stage_id])) {
						_this.checklist[stage_id].forEach((field, index) => {
							if (field.type && field.name && field.value) {
								if (field.type == 'file' && field.name == prop_name) {
									field.value.forEach((item, index2) => {
										if (item.name == params.filename) {
											field.value.splice(index2, 1);
										}
									});
								}
							}
						});
					}
				}
			}).catch(function (error) {

				if (!_this.isEmpty(_this.stage.fields_list)) {
					_this.stage.fields_list.forEach((field, index) => {
						if (field.type && field.name && field.value) {
							if (field.type == 'file' && field.name == prop_name) {
								field.value.forEach((item, index2) => {
									if (item.name == params.filename) {
										field.value.splice(index2, 1);
									}
								});
							}
						}
					});
				}

				CommonService.log('error', 'removeFile()::axios', error);

			});
		},
		uploadFile(stage_id, prop_name, value) {

			let uploadProgress = 0;
			let uploadReadyState = false;

			//let section = this.section;
			let section = 'funnels';
			let data = new FormData();

			if (typeof section !== "undefined" && value.files.length) {
				for (let file of value.files) {
					data.append(section + '[]', file);
				}

				data.append('section', section);

				if (this.client_id)
					data.append('client_id', this.client_id);

				if (this.requisition_id)
					data.append('requisition_id', this.requisition_id);

				if (this.object_id)
					data.append('object_id', this.object_id);
			}

			if (process.env.NODE_ENV == "development")
				console.debug('uploadFile()', {
					stage_id,
					prop_name,
					value: value,
					post_data: data
				});

			api.post('/common/upload', data, {
				headers: {
					'Content-Type': 'multipart/form-data'
				},
				timeout: 180000,
				onUploadProgress: progressEvent => {

					uploadProgress = Math.round((progressEvent.loaded/progressEvent.total)*100);

					if (process.env.NODE_ENV == "development")
						console.debug('uploadFile()::onUploadProgress', {
							progress: uploadProgress
						});

					if (progressEvent.loaded === progressEvent.total) {

						uploadReadyState = true;
						uploadProgress = 0;

						if (process.env.NODE_ENV == "development")
							console.debug('uploadFile()::onUploadProgress', {
								progress: 'COMPLETED!'
							});
					}
				}
			}).then((response) => {

				if (process.env.NODE_ENV == "development")
					console.debug('uploadFile()::axios', {
						response: response.data
					});

				if (response.status == 200 && response.data.success) {

					uploadReadyState = true;
					uploadProgress = 0;
					if (response.data.files.length) {

						let files = response.data.files;
						if (!this.isEmpty(files)) {
							files.forEach((file, index) => {
								this.setAndValidate(stage_id, prop_name, {
									"guid": file.guid,
									"name": file.filename,
									"type": file.extension,
									"is_upload": true,
									"path": file.path
								});
							});
						}

						this.$emit('onFileUploaded', files, response.data.section);
					}
				}

			}).catch(function (error) {

				uploadReadyState = true;
				uploadProgress = 0;

				CommonService.log('error', 'uploadFile()::axios', error);

			});

			CommonService.log('debug', 'uploadFile()', {variable: prop_name, filename: value.name, files: value.files});

		},
		getStages(funnel_id, client_id, requisition_id) {

			let params = {};
			if (this.isEmpty(funnel_id))
				return null;

			if (this.isEmpty(funnel_id) && (this.isEmpty(client_id) || this.isEmpty(requisition_id)))
				return null;

			if (!this.isEmpty(client_id) && this.isEmpty(requisition_id)) {
				params = {
					mode: 'all',
					funnel_id: funnel_id,
					client_id: client_id,
				};
			} else if (!this.isEmpty(requisition_id)) {
				params = {
					mode: 'all',
					funnel_id: funnel_id,
					requisition_id: requisition_id,
				};
			}

			return api.get('/common/stages', {
				params: params
			})
			.then((response) => {

				CommonService.log('debug', 'getStages()::axios', {response: response.data});

				if (response.status == 200 && response.data.success)
					return response.data.stages;

				return null;
			}).catch(function (error) {

				CommonService.log('error', 'getStages()::axios', error);

			});
		},

		newTaskEvent(field_name, task_type, stage_id) {

			if (typeof task_type !== "undefined") {

				this.new_task_section = 'clients';
				this.new_task_event_type = task_type;
				this.new_task_client_id = this.client_id;
				this.new_task_object_id = this.object_id;
				this.new_task_requisition_id = this.requisition_id;
				this.new_task_field_name = field_name;
				this.new_task_stage_id = stage_id;

				if (process.env.NODE_ENV == "development") {
					console.debug('newTaskEvent()', {
						section: this.new_task_section,
						event_type: this.new_task_event_type,
						client_id: this.new_task_client_id,
						object_id: this.new_task_object_id,
						new_task_field_name: this.new_task_field_name,
						new_task_stage_id: this.new_task_stage_id,
					});
				}

				this.$refs.checkListTaskModal.showModal();
			} else {

				CommonService.log('debug', 'newTaskEvent()', task_type);

			}
		},
		newEventReset() {

			this.new_task_section = null;
			this.new_task_event_type = null;
			this.new_task_client_id = null;
			this.new_task_object_id = null;
			this.new_task_requisition_id = null;
			this.new_task_field_name = null;

			CommonService.log('debug', 'newEventReset()');
		},
		updateTasksList(list) {

			let modal = CommonService.getModal('newTaskModal_changeStage');
			if (modal) {
				modal.hide();
			}

			if (process.env.NODE_ENV == "development")
				console.debug('updateTasksList()', {
					for_stage_id: this.new_task_stage_id,
					for_field_name: this.new_task_field_name,
					tasks: list
				});

			let stage_id = list.stage_id;
			let prop_name = list.prop_name;
			if ((!this.isEmpty(stage_id) && this.new_task_stage_id == stage_id) && (!this.isEmpty(prop_name) && this.new_task_field_name == prop_name)) {
				list.forEach((task, index2) => {

					if (task.is_new) {

						let name = 'Задача';
						if (task.type == 'call')
							name = 'Звонок';
						else if (task.type == 'meet' || task.type == 'meeting')
							name = 'Встреча';
						else if (task.type == 'show' || task.type == 'showing')
							name = 'Показ';
						else if (task.type == 'deal')
							name = 'Сделка';

						this.setAndValidate(stage_id, prop_name, {
							name: name,
							type: task.type,
							date: task.event.schedule_date_string ?? task.created_at,
						});
					}
				});
			}

			this.new_task_field_name = null;
		},

		setAndValidate(stage_id, name, value) {

			let errors = [];
			let parent = null;
			let child = null;
			let parts = name.split('.', 2);
			if (parts.length == 2) {
				parent = parts[0];
				child = parts[1];
			}

			// Валидация значения
			if (this.$refs[name] && typeof this.$refs[name] !== "undefined") {

				if (this.$refs[name].inputLabel) {
					let label = this.$refs[name].inputLabel.replace(/:+$/, '');

					let is_skip = false;
					if (name == 'funnel_id' && value == 0)
						is_skip = true;

					if (this.$refs[name].required && !is_skip) {
						if (value) {
							if (value.toString() == '0' || value.toString() == '-1')
								errors.push('Поле `' + label + '` обязательно к заполнению!');
							else if (this.isEmpty(value))
								errors.push('Поле `' + label + '` обязательно к заполнению!');
						} else {
							errors.push('Поле `' + label + '` обязательно к заполнению!');
						}
					}

					if (!this.isEmpty(errors)) {
						this.errors[name] = {
							name: name,
							label: label,
							errors: errors
						};

						this.$refs[name].valid = false;
						this.$refs[name].invalid = errors.join('<br/>');
					} else {
						this.errors[name] = null;
						this.$refs[name].valid = true;
						this.$refs[name].invalid = false;
					}
				}
			}

			// Установка значения
			if (parent && child) {
				if (!this.isEmpty(this.checklist[stage_id][parent])) {
					if (typeof (this.checklist[stage_id][parent][child]) !== "undefined") {
						this.checklist[stage_id][parent][child] = value;
					} else {
						this.checklist[stage_id][parent] = {
							...{
								[child]: value
							},
							...this.checklist[stage_id][parent]
						};
					}
				} else {
					this.checklist[stage_id][parent] = {
						[child]: value
					};
				}
			} else if (parent == null && name) {
				this.checklist[stage_id][name] = value;
			} else {
				if (parent && child)
					this.checklist[stage_id][parent][child] = null;
				else
					this.checklist[stage_id][name] = null;
			}

			this.stages[stage_id]['fields_list'][name]['value'] = this.checklist[stage_id][name];

			console.info(this.stages);

			this.is_form_changed = true;

			CommonService.log('debug', 'setAndValidate()', { name: name, value: value, is_form_changed: this.is_form_changed, errors: errors });


			if (!this.isEmpty(errors))
				return false;

			return true;
		},
		validateAll(fields) {

			this.errors = [];
			let is_valid = false;
			let collection = CommonService.proxyToObject(fields);

			for (const [name, value] of Object.entries(collection)) {

				let input_is_valid = true;
				if (typeof name !== "undefined") {
					if (!this.setAndValidate(name, value, true)) {
						input_is_valid = false;
					}
				}

				CommonService.log('log', 'Validate: ' + name + ', is valid? - ' + input_is_valid.toString());
			}

			if (typeof Object.values(this.errors) == "object") {
				this.errors = CommonService.removeEmpty(Object.values(this.errors));
				this.errors = Object.values(this.errors);
				
				
				if (typeof (this.errors[0]) !== "undefined") {
					if (!this.isEmpty(this.errors[0].name)) {
						// console.log(this.errors[0].name);
						
						let invalid_input = this.errors[0].name;
						if (invalid_input && typeof invalid_input !== "undefined") {
							let input = this.$refs[invalid_input].$el;
							const inputes = this.errors.map(error => this.$refs[error.name].$el)
							if (input) {
								this.$nextTick(() => CommonService.scrollIntoMaxTopView(inputes, 70));
							}
						}
					}
				}
			}

			is_valid = this.isEmpty(this.errors);

			CommonService.log('debug', 'validateAll()', {fields: collection, is_valid: is_valid, errors: this.errors});

			return is_valid;
		},
		addEditCheckList() {
			if (this.validateAll(this.checklist)) {

				this.is_form_changed = false;
				api.post('/common/checklist', this.checklist).then((response) => {

					if (process.env.NODE_ENV == "development")
						console.debug('addEditCheckList()::axios', {
							response: response.data
						});

					if (response.status == 200 && response.data.success) {

						this.resultsModalState.state = 'success';

						if (this.item.id)
							this.resultsModalState.title = 'Чек-лист был успешно сохранён!';
						else
							this.resultsModalState.title = 'Чек-лист клиент был успешно добавлен!';

						if (!this.isEmpty(response.data.requisition_id))
							this.item.id = response.data.requisition_id;

					} else {
						this.resultsModalState.state = 'error';

						if (this.item.id)
							this.resultsModalState.title = 'Ошибка сохранения чек-листа.';
						else
							this.resultsModalState.title = 'Ошибка добавления чек-листа.';

						if (!this.isEmpty(response.data.errors))
							this.resultsModalState.message = response.data.errors.join('<br/>');

					}

				}).catch(function (error) {

					CommonService.log('error', 'addEditCheckList()::axios', error);

				});

				this.resultsModalState.state = '';
				this.resultsModalState.title = '';
				this.resultsModalState.message = '';
			}
		},
		resetData() {
			this.section = null;
			this.funnel_id = null;
			this.client_id = null;
			this.object_id = null;
			this.requisition_id = null;
			this.stages = null;
			this.checklist = [];
			this.resultsModalState = {
				state: '',
					title: '',
					message: ''
			};

			this.new_task_section = null;
			this.new_task_event_type = null;
			this.new_task_client_id = null;
			this.new_task_object_id = null;
			this.new_task_requisition_id = null;
			this.new_task_field_name = null;
			this.new_task_stage_id = null;

			this.is_form_changed = false;
		}
	},

	mounted() {

		let modal = document.getElementById('checkListModal');
		if (modal && typeof modal !== "undefined") {

			let _this = this;
			modal.addEventListener('hidden.bs.modal', function (event) {
				_this.resetData();
				_this.emitter.emit("global.modalClose", modal);
			});

			modal.addEventListener('shown.bs.modal', function (event) {

				let target = event.relatedTarget
				if (typeof target !== "undefined") {
					if (typeof target.getAttribute('data-bs-client-id') != "undefined") {
						_this.section = 'clients';
						_this.client_id = Number(target.getAttribute('data-bs-client-id'));
					} else {
						_this.client_id = null;
					}

					if (typeof target.getAttribute('data-bs-requisition-id') != "undefined") {
						_this.section = 'requisitions';
						_this.requisition_id = Number(target.getAttribute('data-bs-requisition-id'));
					} else {
						_this.requisition_id = null;
					}

					if (typeof target.getAttribute('data-bs-funnel-id') != "undefined") {

						_this.funnel_id = Number(target.getAttribute('data-bs-funnel-id'));
						_this.getStages(_this.funnel_id, _this.client_id, _this.requisition_id).then(stages => {

							if (!_this.isEmpty(stages)) {
								_this.stages = stages;
								Object.entries(_this.stages).forEach((stage, index) => {

									let fields = [];
									if (!_this.isEmpty(stage[1]['fields_list'])) {
										Object.entries(stage[1]['fields_list']).forEach((step, index) => {
											console.info(step);
											fields = {...fields, ...{
												[step[1].name]: step[1].value
											}};
										});
									}

									_this.checklist = {..._this.checklist, ...{
										[stage[0]]: fields
									}};
								});
							}

							console.info(_this.checklist);
						});
					} else {
						_this.stages = null;
					}
				}

				if (process.env.NODE_ENV == "development") {
					console.debug('checkListModal::show.bs.modal', {
						section: _this.section,
						client_id: _this.client_id,
						requisition_id: _this.requisition_id,
					});
				}

				_this.emitter.emit("global.modalShown", modal);
			});
		}
	},
	watch: {

	},
	computed: {


	}
}
</script>

<style lang="scss">

/*$popover-border-color: #8EB3F5;

.popover {
	border-color: $popover-border-color !important;

	&-arrow {
		&::before {
			border-bottom-color: $popover-border-color !important;
		}
	}
}*/
</style>