<template>
    <div class="modal fade modal-fullscreen" id="autoSearchResultModal" ref="autoSearchResultModal" tabindex="-1" aria-labelledby="Результаты автопоиска" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
        <div class="modal-dialog modal-fullscreen modal-fullscreen modal-dialog-scrollable">
            <div class="modal-content p-0 rounded-1">
                <div class="modal-header row p-0 pt-2 px-3">
                    <div class="d-flex col-12 align-items-center container-fluid pt-2 px-3 mb-2">
                        <span class="modal-title fs-2 fw-semi font-semi me-auto" style="max-width: 90%;">Результаты автопоиска</span>
                        <button type="button" class="d-flex ms-auto btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
					<div class="d-flex col-12 text-nowrap-mask scroller scroller-x px-20 pb-0 mb-0" role="tablist">
						<NavTabs v-if="!this.isEmpty(this.tabItems)"
								 id="autoSearchResultTabs"
								 ref="autoSearchResultTabs"
								 :navItems="this.tabItems"
								 :activeItem="this.current_tab"
								 @onClick="(target_id) => this.setSection(target_id)" />
					</div>
                </div>
                <div class="modal-body d-flex flex-column border-0 p-0">

					<div v-if="!this.isEmpty(this.errors)" class="d-flex flex-column">
						<span class="alert alert-warning mb-2 px-20 text-center"
							  v-for="(error, index) in this.errors"
							  :key="index">
							<img :src="require('@/assets/icons/warning.svg')" alt="Warning" class="d-inline-flex m-auto mb-1 me-2" width="14" height="14" />
							{{ error }}
						</span>
					</div>

					<div v-if="!this.isEmpty(this.input_filter)" class="my-auto pt-4">
						<ObjectsList currentSection="autosearch"
									 ref="autoSearchObjectsList"
									 :currentPage="1"
									 :perPage="this.per_page_size"
									 :favouredList="this.favoured"
									 :inputFilters="this.input_filter"
									 :viewedList="this.viewed"
									 :asfilterId="this.filter_id"
									 layout="short"
									 @objectsLoaded="this.objectsLoaded(this.section)"
									 @addRemoveObjectFavour="(object_id) => this.setObjectHasFavoured(object_id)"
									 @addObjectToIgnored="(object_id) => this.setObjectHasIgnored(object_id)"
									 @addRemoveObjectArchive="(object_id) => this.setObjectHasArchived(object_id)"
									 @addRemoveObjectViewed="(object_id) => this.setObjectHasViewed(object_id)" />
					</div>

					<div v-if="this.objects_is_loading && this.is_ready && (this.total > this.per_page_size) && !this.isEmpty(this.input_filter)"
						 class="position-sticky d-flex flex-column mt-auto my-0 bottom-0 bg-white d-flex align-items-center justify-content-center pt-2 border-top border-brd-primary"
						 style="z-index: 11;">

						<Pagination :initialPage="this.page_number"
									:itemsList="this.newArray(this.total)"
									:labels="{
										first: false,
										last: false,
										previous: false,
										next: false
									}"
									:pageSize="this.per_page_size"
									:disableDefaultStyles="true"
									@changePage="(page) => this.setPageNumber(page)"
									:maxPages="3" />
					</div>
					<div class="w-100 px-4 mb-2 pt-4 border-top border-brd-primary">
						<div class="d-flex align-items-center mb-20">
							<div class="d-flex align-items-center justify-content-center me-auto cursor-pointer"
								 data-bs-toggle="modal"
								 data-bs-target="#autoSearchEditModal"
								 :data-bs-asfilter-id="this.filter_id">
								<button class="crm-btn btn btn-outline-light bg-light-gray border border-brd-primary me-10 rounded-3">
									<img :src="require('@/assets/icons/options-icon.svg')" alt="Этап воронки" width="22" height="22" class="d-block">
								</button>
								<span class="pe-20">Параметры автопоиска</span>
							</div>
							<div v-if="this.is_beta_tester && (this.match_total > 0 || this.similar_total > 0)" class="d-flex align-items-center justify-content-center cursor-pointer"
								 @click="this.clearResults()">
								<button class="crm-btn btn btn-outline-danger me-10 rounded-3 icon icon-color-danger">
									<img :src="require('@/assets/icons/basket-icon.svg')" alt="Этап воронки" width="22" height="22" class="d-block">
								</button>
								<span class="text-danger">Очистить результаты</span>
							</div>
						</div>
						<div class="d-flex flex-column">
							<button type="button"
									class="d-flex align-items-center justify-content-center btn btn-outline bg-light-gray border border-brd-primary col-12 rounded-3 py-3 text-white fs-3"
									@click="this.showHideParams()">
								<span class="text-font-secondary me-10">Параметры отображения</span>
								<img v-if="!this.isParamsOpened" :src="require('@/assets/icons/arr-sm-up-icon.svg')" style="margin-top: 6px;" alt="Стрелка" width="12" height="12" class="d-block">
								<img v-else :src="require('@/assets/icons/arr-sm-down-icon.svg')" alt="Стрелка" width="12" height="12" class="d-block">
							</button>
							<div class="w-100 my-20 my-2 mb-2" id="autoSearchResultViewOptions" v-show="this.isParamsOpened">
								<span class="d-block text-font-light mb-20">Параметры отображения</span>
								<ul class="m-0 p-0">
									<li>
										<Switcher inputLabel="Не просмотрернные"
												  :input-value="(!this.isEmpty(this.sort_order.not_viewed)) ? this.sort_order.not_viewed : null"
												  inputClass="px-0"
												  inputWrapClass="flex-column mb-3"
												  @setValue="(value) => this.sort_order.not_viewed = Boolean(value)" />
									</li>
									<li>
										<Switcher inputLabel="Не отправленные"
												  :input-value="(!this.isEmpty(this.sort_order.not_sended)) ? this.sort_order.not_sended : null"
												  inputClass="px-0"
												  inputWrapClass="flex-column mb-3"
												  @setValue="(value) => this.sort_order.not_sended = Boolean(value)" />
									</li>
									<li>
										<Switcher inputLabel="Объекты компании"
												  :input-value="(!this.isEmpty(this.sort_order.company)) ? this.sort_order.company : null"
												  inputClass="px-0"
												  inputWrapClass="flex-column mb-3"
												  @setValue="(value) => this.sort_order.company = Boolean(value)" />
									</li>
									<li>
										<Switcher inputLabel="Сортировать по цене"
												  :input-value="(!this.isEmpty(this.sort_order.price)) ? this.sort_order.price : null"
												  inputClass="px-0"
												  inputWrapClass="flex-column mb-3"
												  @setValue="(value) => this.sort_order.price = Boolean(value)" />
									</li>
									<!--							<li>
																	<Switcher inputLabel="С комиссией"
																			  :input-value="(!this.isEmpty(this.sort_order.with_commission)) ? this.sort_order.with_commission : null"
																			  inputClass="px-0"
																			  inputWrapClass="flex-column mb-3"
																			  @setValue="(value) => this.sort_order.with_commission = parseInt(value)" />
																</li>-->
									<li>
										<Switcher inputLabel="Только с фотографиями"
												  :input-value="(!this.isEmpty(this.sort_order.with_photo)) ? this.sort_order.with_photo : null"
												  inputClass="px-0"
												  inputWrapClass="flex-column mb-3"
												  @setValue="(value) => this.sort_order.with_photo = parseInt(value)" />
									</li>
								</ul>
								<span v-if="!this.isEmpty(this.autosearch)" class="text-font-light fs-5 mb-3">Параметры поиска объектов</span>
								<ul v-if="!this.isEmpty(this.autosearch)" class=" d-flex flex-wrap m-0 p-0 mb-10">
									<li v-for="(filter, field, index) in this.autosearch.filter"
										:key="index"
										class="d-inline-flex py-1 me-10">
										<span class="font-semi fw-semi fs-4" :data-field="field">
											{{ filter.label }}: <span class="text-font-secondary fw-normal">{{ filter.value }}</span>
										</span>
									</li>
									<li class="d-inline-flex py-1 me-10">
										<span class="font-semi fw-semi fs-4">
											ID фильтра: <span class="text-font-secondary fw-normal">{{ this.filter_id }}</span>
										</span>
									</li>
									<li class="d-inline-flex py-1 me-10">
										<span class="font-semi fw-semi fs-4">
											Автопоиск:
											<span v-if="(this.autosearch.active)" class="text-success">вкл.</span>
											<span v-else class="text-danger">откл.</span>
										</span>
									</li>
									<li class="d-inline-flex py-1 me-10">
										<span class="font-semi fw-semi fs-4">
											Уведомления:
											<span v-if="(this.autosearch.notify)" class="text-success">вкл.</span>
											<span v-else class="text-danger">откл.</span>
										</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
            </div>
        </div>
    </div>
</template>

<script>
	import api from "@/api";
	import NavTabs from "@/components/common/NavTabs";
	import CommonService from "@/services/CommonService";
	import Pagination from "@/components/common/Pagination";
	import Switcher from "@/components/inputs/Switcher";
	import { defineComponent, defineAsyncComponent } from 'vue';

    export default {
		name: "AutoSearchResultModal",
        components: {
			Pagination,
			'ObjectsList': defineAsyncComponent(() =>
				import("@/components/objects/ObjectsList"),
			),
			Switcher,
			NavTabs,
        },
        data() {
            return {
                isParamsOpened: false,
				is_ready: false,
				objects_is_loading: true,
				match_is_ready: false,
				similar_is_ready: false,
				favoured_is_ready: false,
                tabItems: [
                    {
						id: 'match-tab',
						label: "Соответствуют запросу",
						title: "Соответствуют запросу",
						data_bs_toggle: 'tab',
						data_bs_target: '#match',
						count: null,
						active: true,
						href: '#',
						action: () => console.log('Tab 1 clicked!')
					}, 
					// {
					// 	id: 'similar-tab',
					// 	label: "Подобные варианты",
					// 	title: "Подобные варианты",
					// 	data_bs_toggle: 'tab',
					// 	data_bs_target: '#similar',
					// 	count: null,
					// 	active: false,
					// 	href: '#',
					// 	action: () => console.log('Tab 2 clicked!')
					// },
					{
						id: 'favoured-tab',
						label: "Избранное",
						title: "Избранное",
						data_bs_toggle: 'tab',
						data_bs_target: '#favoured',
						count: null,
						active: false,
						href: '#',
						action: () => console.log('Tab 2 clicked!')
					},
                ],
				sort_order: {
					company: false,
					price: false,
					with_commission: false,
					with_photo: false,
					not_viewed: false,
					not_sended: false,
				},

				filter_id: null,
				autosearch: null,
				per_page_size: 10,

				total: 0,
				match_total: 0,
				match_total_new: 0,
				page_number: 1,

				match_errors: null,
				match_page_number: 1,
				similar_total: 0,
				similar_total_new: 0,
				similar_errors: null,
				similar_page_number: 1,
				favoured_total: 0,
				favoured_errors: null,
				favoured_page_number: 1,
				is_loading_process: true,

				input_filter: null,
				section: null,
				current_tab: null,

				count: 0,
				match: null,
				viewed: null,
				similar: null,
				favoured: null,
				ignored: null,
				sended: null,

				errors: [],
            }
        },
		methods: {
			showNote(message) {
				return alert(message);
			},
			isEmpty(data) {
				return CommonService.isEmpty(data);
			},
			inArray(needle, haystack) {
				return CommonService.inArray(needle, haystack);
			},
			newArray(length) {
				return CommonService.newArray(length);
			},
			resetData() {
				this.isParamsOpened = false;
				this.is_ready = false;
				this.match_is_ready = false;
				this.similar_is_ready = false;
				this.favoured_is_ready = false;
				this.is_loading_process = true;

				this.input_filter = null;
				this.section = null;
				this.current_tab = null;

				this.sort_order = {
					company: false,
					price: false,
					with_commission: false,
					with_photo: false,
					not_viewed: false,
					not_sended: false,
				};
				this.match = null;
				this.viewed = null;
				this.match_viewed = null;
				this.similar_viewed = null;
				this.similar = null;
				this.favoured = null;
				this.ignored = null;
				this.sended = null;

				this.total = 0;
				this.page_number = 1;

				this.match_total = 0;
				this.match_total_new = 0;
				this.match_page_number = 1;
				this.similar_total = 0;
				this.match_total_new = 0;
				this.similar_page_number = 1;
				this.favoured_total = 0;
				this.favoured_page_number = 1;

				let _this = this;
				CommonService.debounce(() => _this.tabItems.forEach(item => {
					item.active = (item.id == _this.current_tab);
				}), 600);

				this.errors = [];
			},
			clearResults() {
				if (confirm('Вы действительно желаете сбросить результаты автопоиска?')) {
					let filter_id = this.filter_id;
					if (!this.isEmpty(filter_id)) {
						return api.delete('/autosearch/results', {
							params: {
								filter_id: filter_id
							}
						}).then((response) => {

							CommonService.log('debug', 'clearResults()::axios', {response: response.data});

							if (response.status == 200 && response.data.success) {
								this.resetData();
								this.getAllObjects(filter_id, 1)
							}
						}).catch(function (error) {

							CommonService.log('error', 'clearResults()::axios', error);

						});
					} else {
						return null;
					}
				}
			},
			async getResults(filter_id, section, sort_order, page) {

				let _this = this;
				if (typeof filter_id == "undefined")
					return null;

				if (typeof page == "undefined")
					page = 1;
				else
					page = Number(page);

				if (section !== 'sended')
					_this.is_loading_process = true;

				if (!this.isEmpty(filter_id)) {
					return api.get('/autosearch/results', {
						params: {
							filter_id: filter_id || null,
							section: section || null,
							sort_order: sort_order || null,
							per_page: _this.per_page_size,
							offset: ((page - 1) * _this.per_page_size)
						}
					})
					.then((response) => {

						CommonService.log('debug', 'getResults()::axios', {response: response.data});

						if (response.status == 200 && response.data.success) {
							return response.data;
						} else return null;

					}).catch(function (error) {

						if (section !== 'sended')
							_this.is_loading_process = false;

						CommonService.log('error', 'getResults()::axios', error);
					});
				} else {
					return null;
				}
			},
			objectsLoaded(section) {
				CommonService.log('debug', 'objectsLoaded()', {section: section});
				//this.is_loading_process = false;

				if (section == 'match')
					this.match_is_ready = true;

				if (section == 'similar')
					this.similar_is_ready = true;

				if (section == 'favoured')
					this.favoured_is_ready = true;

				this.$nextTick(() => this.setViewedAutosearch('#autoSearchResultModal .modal-body .card.object-item'));
			},
			updateObjectsList(part, section) {

				CommonService.log('debug', 'updateObjectsList()', {part: part, section: section});

				/*if (part == 'match')
					this.getMatchObjects(this.filter_id, this.match_page_number)
				else if (part == 'similar')
					this.getSimilarObjects(this.filter_id, this.similar_page_number)*/
				/*else if (part == 'sended')
					this.getSendedObjects(this.filter_id);
				else if (part == 'favoured')
					this.getFavouredObjects(this.filter_id);*/

			},
			getInfo(filter_id) {
				let _this = this;
				api.get('/autosearch/info', {
					params: {
						filter_ids: [filter_id],
						only_active: false
					}
				}).then((response) => {

					CommonService.log('debug', 'getInfo()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {
						_this.autosearch = (!_this.isEmpty(response.data.list[filter_id])) ? response.data.list[filter_id].autosearch : [];
					}

				}).catch(function (error) {
					CommonService.log('error', 'getInfo()::axios', error);
				});
			},
			getMatchObjects(filter_id, page) {
				let _this = this;
				_this.match_is_ready = false;
				return _this.getResults(filter_id, 'match', _this.sort_order, page).then(data => {

					if (data === null) {
						_this.match_total = 0;
						_this.match = null;
						return;
					}

					if (!_this.isEmpty((data.errors)))
						_this.match_errors = data.errors;
					else
						_this.match_errors = null;

					if (!_this.isEmpty((data.count)))
						_this.match_total = data.count;
					else
						_this.match_total = 0;

					if (!_this.isEmpty(data.count)) {
						_this.tabItems.filter(item => {
							if (item.id === 'match-tab') {
								item.count = data.count;
							}
						});
					} else {
						_this.tabItems.filter(item => {
							if (item.id === 'match-tab') {
								item.count = 0;
							}
						});
					}

					if (!_this.isEmpty((data.results))) {
						_this.match = data.results;
						CommonService.log('info', 'getMatchObjects()', _this.match);
					} else {
						_this.match = null;
					}

					if (!_this.isEmpty((data.results.viewed)))
						_this.match_viewed = [..._this.viewed, ...data.results.viewed];

					_this.match_is_ready = true;
					// _this.is_loading_process = false;
					_this.setSection('match-tab');
					return _this.match;
				});
			},
			// getSimilarObjects(filter_id, page) {
			// 	let _this = this;
			// 	_this.similar_is_ready = false;
			// 	return _this.getResults(filter_id, 'similar', _this.sort_order, page).then(data => {

			// 		if (data === null) {
			// 			_this.similar_total = 0;
			// 			_this.similar = null;
			// 			return;
			// 		}

			// 		if (!_this.isEmpty((data.errors)))
			// 			_this.similar_errors = data.errors;
			// 		else
			// 			_this.similar_errors = null;

			// 		if (!_this.isEmpty((data.count)))
			// 			_this.similar_total = data.count;
			// 		else
			// 			_this.similar_total = 0;

			// 		if (!_this.isEmpty(data.count)) {
			// 			_this.tabItems.filter(item => {
			// 				if (item.id === 'similar-tab') {
			// 					item.count = data.count;
			// 				}
			// 			});
			// 		} else {
			// 			_this.tabItems.filter(item => {
			// 				if (item.id === 'similar-tab') {
			// 					item.count = 0;
			// 				}
			// 			});
			// 		}

			// 		// if (!_this.isEmpty((data.results))) {
			// 		// 	_this.similar = data.results;
			// 		// 	CommonService.log('info', 'getSimilarObjects()', _this.similar);
			// 		// } else {
			// 		// 	_this.similar = null;
			// 		// }

			// 		if (!_this.isEmpty((data.results.viewed)))
			// 			_this.match_viewed = [..._this.viewed, ...data.results.viewed];

			// 		// _this.similar_is_ready = true;
			// 		// // _this.is_loading_process = false;
			// 		// return _this.similar;
			// 	});
			// },
			getFavouredObjects(filter_id, page) {
				let _this = this;
				_this.favoured_is_ready = false;
				return _this.getResults(filter_id, 'favoured', _this.sort_order, page).then(data => {

					if (data === null) {
						_this.favoured_total = 0;
						_this.favoured = null;
						return;
					}

					if (!_this.isEmpty((data.errors)))
						_this.favoured_errors = data.errors;
					else
						_this.favoured_errors = null;

					if (!_this.isEmpty(data.total))
						_this.favoured_total = data.total;
					else
						_this.favoured_total = 0;

					if (!_this.isEmpty(_this.favoured_total)) {
						_this.tabItems.filter(item => {
							if (item.id === 'favoured-tab') {
								item.count = _this.favoured_total;
							}
						});
					} else {
						_this.tabItems.filter(item => {
							if (item.id === 'favoured-tab') {
								item.count = 0;
							}
						});
					}

					if (!_this.isEmpty(data.results)) {
						_this.favoured = data.results;
						CommonService.log('info', 'getFavouredObjects()', _this.favoured);
					} else {
						_this.favoured = null;
					}

					if (!_this.isEmpty((data.results.viewed)))
						_this.viewed = [..._this.viewed, ...data.results.viewed];

					_this.favoured_is_ready = true;
					// _this.is_loading_process = false;
					return _this.favoured;
				});
			},
			getSendedObjects(filter_id, page) {
				let _this = this;
				return _this.getResults(filter_id, 'sended', _this.sort_order, page).then(data => {

					if (data === null) {
						_this.sended = null;
						return;
					}

					if (!_this.isEmpty((data.results.sended))) {
						_this.sended = data.results.sended;
						CommonService.log('info', 'getFavouredObjects()', _this.sended);
					} else {
						_this.sended = null;
					}

					if (!_this.isEmpty((data.results.viewed)))
						_this.viewed = [..._this.viewed, ...data.results.viewed];

					// _this.is_loading_process = false;
					return _this.sended;
				});
			},
			setSection(section) {

				this.errors = [];

				CommonService.log('info', 'setSection()', section);

				this.is_ready = false;
				// this.objects_is_loading = false;
				if (section == 'match-tab') {
					this.section = 'match';
					this.total = this.match_total;

					if (!CommonService.isEmpty(this.match_errors))
						this.errors.push(this.match_errors);

					this.viewed = this.match_viewed;
					this.page_number = this.match_page_number;
					this.is_ready = this.match_is_ready;
					this.input_filter = {
						objects_ids: (!CommonService.isEmpty(this.match)) ? CommonService.resetProxy(this.match) : null,
						asfilter_id: this.filter_id
					};
				} else if (section == 'similar-tab') {
					this.section = 'similar';
					this.total = this.similar_total;

					if (!CommonService.isEmpty(this.similar_errors))
						this.errors.push(this.similar_errors);

					this.viewed = this.similar_viewed;
					this.page_number = this.similar_page_number;
					this.is_ready = this.similar_is_ready;
					this.input_filter = {
						objects_ids: (!CommonService.isEmpty(this.similar)) ? CommonService.resetProxy(this.similar) : null,
						asfilter_id: this.filter_id
					};
				} else if (section == 'favoured-tab') {
					this.section = 'favoured';
					this.total = this.favoured_total;

					if (!CommonService.isEmpty(this.favoured_errors))
						this.errors.push(this.favoured_errors);

					this.page_number = this.favoured_page_number;
					this.is_ready = this.favoured_is_ready;
					this.input_filter = {
						objects_ids: (!CommonService.isEmpty(this.favoured)) ? CommonService.resetProxy(this.favoured) : null,
						asfilter_id: this.filter_id
					};
				}

				if (section !== this.current_tab)
					this.current_tab = section;

			},
			showHideParams() {
				this.isParamsOpened = !this.isParamsOpened;
				let body = document.querySelector('#autoSearchResultModal .modal-body');
				let offset = body.scrollTop;
				console.info(offset);
				if (this.isParamsOpened) {
					setTimeout(() => {
						let height = document.querySelector('#autoSearchResultViewOptions').scrollHeight;
						body.scrollTop = (offset + (height + 30));
						console.info(body.scrollTop);
					}, 300);
				} else {
					setTimeout(() => {
						let height = document.querySelector('#autoSearchResultViewOptions').scrollHeight;
						body.scrollTop = (offset - (height + 30));
						console.info(body.scrollTop);
					}, 300);
				}
			},
			setPageNumber(page) {
				if (this.current_tab == 'match-tab') {
					this.match_page_number = page;
					this.page_number = page;
				} else if (this.current_tab == 'similar-tab') {
					this.similar_page_number = page;
					this.page_number = page;
				} else if (this.current_tab == 'favoured-tab') {
					this.favoured_page_number = page;
					this.page_number = page;
				}
			},
			setObjectHasFavoured(object_id) {
				CommonService.log('debug', 'setObjectHasFavoured()', {object_id: object_id});
				this.getFavouredObjects(this.filter_id, this.favoured_page_number);
			},
			setObjectHasIgnored(object_id) {
				CommonService.log('debug', 'setObjectHasIgnored()', {object_id: object_id});

				if (!this.isEmpty(this.match))
					this.match = this.match.filter(item => item !== object_id);

				if (!this.isEmpty(this.similar))
					this.similar = this.similar.filter(item => item !== object_id);

				if (!this.isEmpty(this.favoured))
					this.favoured = this.favoured.filter(item => item !== object_id);

				if (this.current_tab == 'match-tab') {
					let match_total = (this.match_total - 1);
					if (match_total >= 0) {
						this.match_total = match_total;
					}
				} else if (this.current_tab == 'similar-tab') {
					let similar_total = (this.similar_total - 1);
					if (similar_total >= 0) {
						this.similar_total = similar_total;
					}
				} else if (this.current_tab == 'favoured-tab') {
					if (this.favoured.indexOf(object_id) !== -1) {
						let favoured_total = (this.favoured_total - 1);
						if (favoured_total >= 0) {
							this.favoured_total = favoured_total;
						}
					}
				}

				this.tabItems.forEach(item => {
					if (item.id == 'match-tab') {
						item.count = this.match_total;
					} else if (item.id == 'similar-tab') {
						item.count = this.similar_total;
					} else if (item.id == 'favoured-tab') {
						item.count = this.favoured_total;
					}
				});
			},
			setObjectHasArchived(object_id) {
				CommonService.log('debug', 'setObjectHasArchived()', {object_id: object_id});
			},
			setObjectHasViewed(object_id) {

				CommonService.log('debug', 'setObjectHasViewed()', {object_id: object_id});

				if (this.inArray(object_id, this.match) && !this.inArray(object_id, this.viewed)) {

					let match_total = this.match_total_new;
					match_total--;

					if (match_total >= 0)
						this.match_total_new = match_total;

				}

				if (this.inArray(object_id, this.similar) && !this.inArray(object_id, this.viewed)) {

					let similar_total = this.similar_total_new;
					similar_total--;

					if (similar_total >= 0)
						this.similar_total_new = similar_total;

				}
			},
			setViewedAutosearch(sentinalName) {

				const _this = this;
				const sentinals = document.querySelectorAll(sentinalName);
				sentinals.forEach((sentinal, index) => {

					let handler = (entries) => {

						if (entries[0].isIntersecting) {

							let object_id = sentinal.id.replace('objectItem_', '');
							setTimeout(() => {
								api.put('/objects/viewed', {
									object_id: object_id,
									current: false
								}).then((response) => {
									CommonService.log('debug', 'setViewedAutosearch()::axios', {response});

									if (response.status == 200 && response.data.success) {
										//if (_this.viewed.indexOf(object_id) == -1) {
										_this.viewed.push(object_id);
										//_this.$refs.ObjectsList.viewedList(_this.viewed);
										CommonService.log('debug', 'setViewedAutosearch::added', {object_id: object_id});
										//}
									}

									_this.$emit('addRemoveObjectViewed', object_id);
								}).catch(function (error) {
									CommonService.log('error', 'setViewedAutosearch()::axios', error);
								});
							}, 1200);

						}
					}
					let observer = new window.IntersectionObserver(handler);
					observer.observe(sentinal);
				});

			},
			restartSearch: function (filter_id) { // filter_id

				let params = {
					section: 'requisitions',
					filter_id: filter_id || this.filter_id
				};

				CommonService.log('debug', 'getRequisitions()', params);

				return api.get('/autosearch/restart-search', {
					params: params
				})
				.then((response) => {

					CommonService.log('debug', 'getRequisitions()::axios', {response: response.data});

					this.emitter.emit('restartSearch', filter_id)

					console.debug('getRequisitions()', response.data.success);
					return response.data.success;
				}).catch(function (error) {

					CommonService.log('error', 'getRequisitions()::axios', error);

				});
			},
			getComplexResults(filter_id) {
				return Promise.all([
						this.getMatchObjects(filter_id, this.match_page_number),
						// this.getSimilarObjects(filter_id, this.similar_page_number),
						this.getFavouredObjects(filter_id, this.favoured_page_number),
						this.getSendedObjects(filter_id, 1)
					])
			},
			getAllObjects(filter_id) {
				this.is_loading_process = true;
				this.objects_is_loading = true;

				this.$nextTick(() => {
					this.getComplexResults(filter_id).finally(() => {
						if (!this.autosearch?.total) {
							this.restartSearch().then(() => {
								this.getComplexResults(filter_id).finally(() => {
									this.getInfo(filter_id);
									if (this.match_is_ready && this.similar_is_ready && this.favoured_is_ready) {
										this.is_loading_process = false;
										this.objects_is_loading = false;
									}
								})
							})
						} else {
							this.getInfo(filter_id);
							if (this.match_is_ready && this.similar_is_ready && this.favoured_is_ready) {
								this.is_loading_process = false;
								this.objects_is_loading = false;
							}
						}
					})
				});

				

				this.viewed = [];
				this.match_viewed = [];
				this.similar_viewed = [];

				this.setSection('match-tab');
			},
		},
		mounted() {

			var _this = this;
			var modal = this.$refs.autoSearchResultModal;
			if (modal && typeof modal !== "undefined") {

				modal.addEventListener('hidden.bs.modal', function (event) {
					_this.resetData();
					_this.emitter.emit("global.modalClose", modal);
				});

				modal.addEventListener('show.bs.modal', function (event) {
					_this.resetData();

					let target = event.relatedTarget
					_this.errors = [];

					let filter_id = target.getAttribute('data-bs-asfilter-id');
					if (typeof filter_id !== "undefined") {
						_this.filter_id = parseInt(filter_id);
						_this.get
						_this.getAllObjects(_this.filter_id);
					}

					if (process.env.NODE_ENV == "development") {
						console.debug('autoSearchResultModal::show.bs.modal', {
							filter_id: _this.filter_id,
							autosearch: _this.autosearch,
						});
					}
					_this.emitter.emit("global.modalShown", modal);
				});

				let modale = document.getElementById('#autoSearchResultModal');
				if (modale) {
					modale.addEventListener('scroll', this.setViewedAutosearch);
				}
			}
		},
		unmounted () {
			let modal = document.getElementById('#autoSearchResultModal');
			if (modal) {
				modal.removeEventListener('scroll', this.setViewedAutosearch);
			}
		},
		watch: {
			match(value, oldValue) {
				console.log(value);
				this.$nextTick(() => {
					console.log(value);
				});
			},
			similar(value, oldValue) {
				this.$nextTick(() => {
					console.log(value);
				});
			},
			favoured(value, oldValue) {
				console.log(value);
				this.$nextTick(() => {
					console.log(value);
				});
			},
			ignored(value, oldValue) {
				console.log(value);
				this.$nextTick(() => {
					console.log(value);
				});
			},
			viewed(value, oldValue) {
				console.log(value);
				this.$nextTick(() => {
					console.log(value);
				});
			},
			sended(value, oldValue) {
				console.log(value);
				this.$nextTick(() => {
					console.log(value);
				});
			},
			'match_page_number': function(val, oldVal) {

				CommonService.log('debug', 'watch:match_page_number', {value: val, old_value: oldVal});

				this.getMatchObjects(this.filter_id, val);
			},
			// 'similar_page_number': function(val, oldVal) {

			// 	CommonService.log('debug', 'watch:similar_page_number', {value: val, old_value: oldVal});

			// 	this.getSimilarObjects(this.filter_id, val);
			// },
			'favoured_page_number': function(val, oldVal) {

				CommonService.log('debug', 'watch:favoured_page_number', {value: val, old_value: oldVal});

				this.getFavouredObjects(this.filter_id, val);
			},
			filters: {
				handler: function(value, oldValue) {
					CommonService.log('debug', 'watch:filters', {value: value, old_value: oldValue});
				},
				deep: true
			},
			sort_order: {
				handler: function(value, oldValue) {

					CommonService.log('debug', 'watch:sort_order', {value: value, old_value: oldValue});

					if (this.isParamsOpened && value) {
						this.getMatchObjects(this.filter_id, 1);
						// this.getSimilarObjects(this.filter_id, 1);
						this.isParamsOpened = false;
					}
				},
				deep: true
			},
		},
		computed: {
			is_beta_tester() {
				let user_id = parseInt(this.$store.getters.userInfo.id);
				let beta_testers = this.$root.config.beta_testers_ids;
				return this.inArray(user_id, beta_testers);
			},
			is_loading: {
				get() {
					return this.is_loading_process;
				},
				set(value) {
					this.is_loading_process = value;
				}
			},
			user_can() {
				return {
					autosearch: {
						edit: this.$store.getters.userInfo.user_can.autosearch && (this.layout == 'default') && (this.item.user_id == this.$store.getters.userInfo.id) && (this.item.type_id == 1 || this.item.heir_type_id == 1),
						view: (this.item.autosearch.error || this.item.autosearch.active > 0 || this.item.autosearch.count > 0) && (this.item.type_id == 1 || this.item.heir_type_id == 1),
					}
				};
			},
			/*input_filter() {
				if (this.current_tab == 'match-tab') {
					return {
						objects_ids: (!CommonService.isEmpty(this.match)) ? CommonService.resetProxy(this.match) : null,
						asfilter_id: this.filter_id
					};
				} else if (this.current_tab == 'similar-tab') {
					return {
						objects_ids: (!CommonService.isEmpty(this.similar)) ? CommonService.resetProxy(this.similar) : null,
						asfilter_id: this.filter_id
					};
				} else if (this.current_tab == 'favoured-tab') {
					return {
						objects_ids: (!CommonService.isEmpty(this.favoured)) ? CommonService.resetProxy(this.favoured) : null,
						asfilter_id: this.filter_id
					};
				}

				return null;
			},*/
		},
    }
</script>