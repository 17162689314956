<template>
	<div ref="wrapper" :class="this.wrapper">
		<div v-if="this.label" class="form-label" :class="this.labelClass">
			{{ this.label }}<sup v-if="this.required" class="text-danger">*</sup>
		</div>
		<div class="d-inline-flex align-items-stretch input-group" :class="this.group">
			<div v-if="this.prepend" v-html="this.prepend" class="input-group-text text-dark-gray border-0 bg-transparent p-0 pe-3" />

			<ul v-if="!this.isEmpty(this.value)" class="list-unstyled d-flex flex-column w-100 mb-2">
				<li v-for="(value, index) of this.value"
					:key="index"
					:ref="'file_'+index"
					class="d-flex flex-fill align-items-center list-item mb-2">

					<img v-if="this.inArray(value.type, ['doc', 'docx'])" :src="require('@/assets/icons/file-word.svg')" alt="Документ Word" width="18" height="18" class="me-2">
					<img v-else-if="this.inArray(value.type, ['xls', 'xlsx'])" :src="require('@/assets/icons/file-excel.svg')" alt="Документ Excel" width="18" height="18" class="me-2">
					<img v-else-if="this.inArray(value.type, ['ppt', 'pptx'])" :src="require('@/assets/icons/file-powerpoint.svg')" alt="Документ PowerPoint" width="18" height="18" class="me-2">
					<img v-else-if="this.inArray(value.type, ['pdf'])" :src="require('@/assets/icons/file-pdf.svg')" alt="PDF-документ" width="18" height="18" class="me-2">
					<img v-else-if="this.inArray(value.type, ['jpg','jpeg','png','bmp','tiff','gif'])" :src="require('@/assets/icons/file-image.svg')" alt="Изображение" width="18" height="18" class="me-2">
					<img v-else :src="require('@/assets/icons/blank-icon.svg')" alt="Файл" width="18" height="18" class="me-2">

					<a v-if="this.isEmpty(value.path)"
					   class="d-inline-flex align-items-center border-bottom-0 text-decoration-none mw-80"
					   :href="value.path"
					   :download="value.name"
					   target="_blank">
					<span class="text-nowrap-mask pb-1 pe-4">
						<span class="fs-4 link link-dashed">{{ value.name }}</span>
					</span>
					</a>
					<span v-else class="d-inline-flex align-items-center border-bottom-0 text-decoration-none mw-80">
						<span class="text-nowrap-mask pb-1 pe-4">
							<span class="fs-4 text-dark-gray">{{ value.name }}</span>
						</span>
					</span>

					<div v-if="this.remove" class="input-group-text align-self-start text-dark-gray border-0 bg-transparent bg-0 p-0">
						<button type="button"
								aria-label="Удалить"
								class="d-flex align-items-center justify-content-center btn btn-outline-secondary bg-light-gray border border-brd-primary rounded-1"
								style="width: 44px; height: 44px;"
								@click="this.emitRemove('file_'+index, index)">
							<img :src="require('@/assets/icons/delete.svg')" class="d-block" alt="Удалить" width="20" height="20">
						</button>
					</div>
				</li>
			</ul>

			<label :for="this.name"
				   class="form-label bg-light-gray px-20 py-3 rounded-3 border border-brd-primary"
				   :class="this.class + ((this.valid) ? ' is-valid' : '') + ((this.invalid) ? ' is-invalid' : '')">
				<img :src="require('@/assets/icons/staple-icon.svg')" alt="Прикрепить файл(ы)" width="18" height="18" class="me-10">
				<span v-if="this.multiple" class="text-font-secondary font-semi fw-semi">Выберите файлы</span>
				<span v-else class="text-font-secondary font-semi fw-semi">Выберите файл</span>
			</label>
			<input class="form-control visually-hidden"
				   type="file"
				   ref="input"
				   :id="this.id"
				   :name="this.name"
				   :placeholder="this.placeholder"
				   :disabled="this.disabled"
				   :readonly="this.readonly"
				   :required="this.required"
				   :multiple="this.multiple"
				   :accept="this.accept"
				   @change="(event) => this.emitValue(event.target.value)">
			<div v-if="this.append" v-html="this.append" class="input-group-text text-dark-gray border-0 bg-transparent bg-0 p-0 ps-3" />

			<div v-if="this.valid && typeof this.valid != 'boolean'" class="valid-feedback" v-html="this.valid" />
			<div v-else-if="this.invalid && typeof this.invalid != 'boolean'" class="invalid-feedback" v-html="this.invalid" />
		</div>
		<div v-if="this.help" class="form-text" :class="this.helpClass" v-html="this.help" />

	</div>
</template>


<script>
import CommonService from "@/services/CommonService";

export default {
	props: {
		inputId: {type: String},
		inputLabel: {type: String},
		inputLabelClass: {type: String},
		inputName: {type: String},
		inputValue: {type: [Array,Object]},
		inputClass: {type: String},
		inputGroupClass: {type: String},
		inputWrapClass: {type: String},
		inputHelpText: {type: String},
		inputHelpClass: {type: String},
		inputPlaceholder: {type: String},
		inputAccept: {type: String},
		inputDisabled: {type: [String, Boolean], default: false},
		inputRequired: {type: [String, Boolean], default: false},
		inputMultiple: {type: Boolean},
		inputReadonly: {type: [String, Boolean], default: false},
		inputPrepend: {type: String},
		inputAppend: {type: String},
		inputRemove: {type: [String, Boolean], default: false},
		inputValid: {type: [String, Boolean], default: false},
		inputInValid: {type: [String, Boolean], default: false},
	},
	data() {
		return {
			id: (typeof this.inputId !== "undefined") ? this.inputId : '',
			label: (typeof this.inputLabel !== "undefined") ? this.inputLabel : '',
			labelClass: (typeof this.inputLabelClass !== "undefined") ? this.inputLabelClass : 'text-dark-gray',
			name: (typeof this.inputName !== "undefined") ? this.inputName : '',
			class: (typeof this.inputClass !== "undefined") ? this.inputClass : '',
			group: (typeof this.inputGroupClass !== "undefined") ? this.inputGroupClass : '',
			wrapper: (typeof this.inputWrapClass !== "undefined") ? this.inputWrapClass : '',
			helpClass: (typeof this.inputHelpClass !== "undefined") ? this.inputHelpClass : '',
			placeholder: (typeof this.inputPlaceholder !== "undefined") ? this.inputPlaceholder : '',
			//disabled: (typeof this.inputDisabled !== "undefined" && this.inputDisabled === 'true'),
			//required: (typeof this.inputRequired !== "undefined" && this.inputRequired === 'true'),
			//readonly: (typeof this.inputReadonly !== "undefined" && this.inputReadonly === 'true'),
			//prepend: (typeof this.inputPrepend !== "undefined") ? this.inputPrepend : false,
			//append: (typeof this.inputAppend !== "undefined") ? this.inputAppend : false,
			remove: (typeof this.inputRemove !== "undefined") ? this.inputRemove : false,
			valid: (typeof this.inputValid !== "undefined") ? this.inputValid : false,
			invalid: (typeof this.inputInValid !== "undefined") ? this.inputInValid : false,
		};
	},
	methods: {
		isEmpty(data) {
			return CommonService.isEmpty(data);
		},
		inArray(needle, haystack) {
			return CommonService.inArray(needle, haystack);
		},
		validateInput(value) {

			if (typeof value == "string") {
				let ext = value.split('.').pop();
				if (typeof ext !== "undefined") {
					if (!CommonService.inArray(ext, this.accept))
						this.invalid = 'Данный тип файла не поддерживается.';
				} else if (!this.isEmpty(this.accept)) {
					this.invalid = 'Данный тип файла не поддерживается.';
				}
			}

			let input = this.$refs.input;
			if (this.invalid)
				input.setCustomValidity(this.invalid);
			else
				input.setCustomValidity("");

		},
		emitValue(value) {
			this.$emit('setValue', {files: this.$refs.input.files, value: value});
		},
		emitRemove(link, index) {
			if (this.$emit('removeValue', this.value[index])) {
				this.$refs[link].remove();
			}
		}
	},
	mounted() {
		this.validateInput();
	},
	watch: {
		'value': function(val, oldVal) {
			this.validateInput(val);
		},
		/*'mask': function(val, oldVal) {
			this.$refs.input.dataset.mask = this.mask;
			this.$refs.input.value = this.value;
		}*/
	},
	computed: {
		disabled() {
			return this.$props.inputDisabled;
		},
		value() {
			return this.$props.inputValue;
		},
		required() {
			return this.$props.inputRequired;
		},
		readonly() {
			return this.$props.inputReadonly;
		},
		multiple() {
			return this.$props.inputMultiple;
		},
		accept() {

			let accept = this.$root.config.upload_accept.files;

			if (typeof this.$props.inputAccept !== "undefined")
				accept = this.$props.inputAccept;

			if (accept && typeof accept === 'string')
				accept = accept.split(',');

			return accept;
		},
		help() {
			return (typeof this.$props.inputHelpText !== "undefined") ? this.$props.inputHelpText : '';
		},
		prepend() {
			return (typeof this.$props.inputPrepend !== "undefined") ? this.$props.inputPrepend : false;
		},
		append() {
			return (typeof this.$props.inputAppend !== "undefined") ? this.$props.inputAppend : false;
		},
	},
}
</script>