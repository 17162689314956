<template>
	<div class="modal fade" id="documentsListModal" tabindex="-1" aria-labelledby="Список документов" aria-hidden="true">
		<div class="modal-dialog modal-fullscreen modal-dialog-centered modal-dialog-scrollable">
			<div class="modal-content p-20">
				<div class="modal-header mb-20 border-0 p-0">
					<span class="modal-title fs-2 fw-semi font-semi">Все документы</span>
					<button type="button" class="d-flex ms-auto btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body d-flex flex-column p-0">
					<ul v-if="!this.isEmpty(this.files_list)"
						class="list-unstyled d-flex flex-column" style="max-height: 65vh;">
						<li v-for="(file, index) in this.files_list.files" :key="index"
							class="d-flex mb-3">
							<p class="mb-0">
								<a :href="file[0].link"
								   target="_blank"
								   download="true"
								   :data-file-id="file[0].id"
								   :data-guid="file[0].guid"
								   :data-funnel-id="file[0].funnel_id"
								   :data-etap-id="file[0].etap_id"
								   :data-type="file[0].type"
								   :data-event-id="file[0].event_id"
								   :data-req-id="file[0].req_id"
								   :data-object-id="file[0].object_id"
								   :data-name-id="file[0].name_id"
								   class="link fs-3 me-auto">
									{{ file[0].name }}
								</a><br/>
								<small class="d-inline-flex mt-2 text-dark-gray">Добавлено {{ file[0].date }}</small>
							</p>
							<a :href="file[0].link"
							   target="_blank"
							   download="true"
							   class="p-3 fs-4 ms-auto">
								<img :src="require('@/assets/icons/download.svg')" alt="Скачать" class="d-inline-flex my-auto ms-0 me-1" width="22" height="22" style="margin-top: 1px;">
							</a>
						</li>
					</ul>
					<div v-else-if="this.files_list == null" class="px-20 pt-2 row-fluid row-cols-1">
						<EmptyList message="Нет документов" />
					</div>
					<div v-else class="px-20 pt-2 row-fluid row-cols-1">
						<Loader />
					</div>
				</div>
				<div class="modal-footer border-0 p-0">
					<button type="button"
							class="btn btn-primary col-12 rounded-3 py-3 text-white fs-3"
							:class="{'disabled' : !(this.object_id || this.client_id || this.requisition_id)}"
							data-bs-toggle="modal"
							data-bs-target="#historyTasksModal"
							:data-bs-section="this.section"
							:data-bs-requisition-id="this.requisition_id"
							:data-bs-client-id="this.client_id"
							:data-bs-object-id="this.object_id">
						Назад
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import CommonService from "@/services/CommonService";
import api from "@/api";
import ResultsModal from "@/components/modals/ResultsModal";
import InputSelect from "@/components/inputs/InputSelect";
import TextArea from "@/components/inputs/TextArea";
import Switcher from "@/components/inputs/Switcher";
import InputText from "@/components/inputs/InputText";
import EmptyList from "@/components/common/EmptyList";
import Loader from "@/components/common/Loader";

export default {
	name: 'DocumentsListModal',
	props: {
		fromSection: { type: String },
		objectId: { type: [String, Number] },
		clientId: { type: [String, Number] },
		requisitionId: { type: [String, Number] },
	},
	data() {
		return {
			section: (typeof this.fromSection !== "undefined") ? this.fromSection : null,
			object_id: (typeof this.objectId !== "undefined") ? this.objectId : null,
			client_id: (typeof this.clientId !== "undefined") ? this.clientId : null,
			requisition_id: (typeof this.requisitionId !== "undefined") ? this.requisitionId : null,
			files_list: null
		}
	},
	components: {
		EmptyList,
		Loader,
	},
	methods: {
		resetData() {
			this.object_id = null;
			this.client_id = null;
			this.requisition_id = null;
			this.files_list = null;
		},
		isEmpty(data) {
			return CommonService.isEmpty(data);
		},
		getFilesList(source_id, section) {
			this.files_list = null;
			return api.get('/common/files', {
				params: {
					section: section,
					source_id: source_id,
				}
			})
			.then((response) => {

				CommonService.log('debug', 'getFilesList()::axios', {response: response.data});

				if (response.status == 200 && response.data.success) {

					if (!this.isEmpty(response.data.list.files))
						return response.data.list;

				}

				return null;
			}).catch(function (error) {

				CommonService.log('error', 'getFilesList()::axios', error);

			});
		},
	},
	mounted() {

		let modal = document.getElementById('documentsListModal');
		if (modal && typeof modal !== "undefined") {

			let _this = this;
			modal.addEventListener('hidden.bs.modal', function (event) {
				CommonService.debounce(() => {
					_this.resetData();
				}, 2000); // fix next payload for HistoryTasksModal.vue
				_this.emitter.emit("global.modalClose", modal);
			});

			modal.addEventListener('shown.bs.modal', function (event) {

				let target = event.relatedTarget
				if (typeof target !== "undefined") {

					if (typeof target.getAttribute('data-bs-from-section') != "undefined")
						_this.section = target.getAttribute('data-bs-from-section');

					if (typeof target.getAttribute('data-bs-object-id') != "undefined")
						_this.object_id = target.getAttribute('data-bs-object-id');

					if (typeof target.getAttribute('data-bs-client-id') != "undefined")
						_this.client_id = target.getAttribute('data-bs-client-id');

					if (typeof target.getAttribute('data-bs-requisition-id') != "undefined")
						_this.requisition_id = target.getAttribute('data-bs-requisition-id');

					if (_this.section == 'objects') {
						_this.getFilesList(_this.object_id, _this.section).then((list) => {
							_this.files_list = list;
						});
					} else if (_this.section == 'clients') {
						_this.getFilesList(_this.client_id, _this.section).then((list) => {
							_this.files_list = list;
						});
					} else if (_this.section == 'requisitions') {
						_this.getFilesList(_this.requisition_id, _this.section).then((list) => {
							_this.files_list = list;
						});
					}
				}

				if (process.env.NODE_ENV == "development") {
					console.debug('documentsListModal::show.bs.modal', {
						modal: modal,
						section: _this.section,
						object_id: _this.object_id,
						client_id: _this.client_id,
						requisition_id: _this.requisition_id,
						files_list: _this.files_list,
					});
				}
				_this.emitter.emit("global.modalShown", modal);
			});
		}
	},
	computed: {

	}
}
</script>