<template>
	<nav v-if="pager.pages && pager.pages.length > 1" aria-label="Page navigation">
		<ul class="pagination justify-content-center"
			:style="ulStyles">
			<li v-if="labels.first"
				class="page-item first"
				:class="{'disabled': pager.currentPage === 1}"
				:style="liStyles">
				<a href="javascript:{}"
				   class="page-link"
				   @click="goToPage(1)"
				   :style="aStyles">
					{{ labels.first }}
				</a>
			</li>
			<li class="page-item previous"
				:class="{'disabled': pager.currentPage === 1}"
				:style="liStyles">
				<a v-if="labels.previous"
				   href="javascript:{}"
				   class="page-link"
				   @click="goToPage(pager.currentPage - 1)"
				   :style="aStyles">
					{{ labels.previous }}
				</a>
				<a v-else href="javascript:{}"
				   class="page-link"
				   @click="goToPage(pager.currentPage - 1)"
				   :style="aStyles">
					<img :src="require('@/assets/icons/arrow-left.svg')" alt="Назад" width="12" height="12">
				</a>
			</li>

			<li v-if="pager.currentPage > maxPages"
				class="page-item"
				:class="{'active': pager.currentPage === 1}"
				:style="liStyles">
				<a href="javascript:{}"
				   class="page-link"
				   @click="goToPage(1)"
				   :style="aStyles">
					1
				</a>
			</li>
			<li v-if="pager.currentPage > maxPages"
				class="page-item p-1 py-2 disabled"
				:style="liStyles">
				<img :src="require('@/assets/icons/dots-horz.svg')" alt="..." class="mt-3" width="12" height="12">
			</li>

			<li v-for="page in pager.pages.slice(0, maxPages)"
				:key="page"
				class="page-item page-number"
				:class="{'active': pager.currentPage === page}"
				:style="liStyles">
				<a href="javascript:{}"
				   class="page-link"
				   @click="goToPage(page)"
				   :style="aStyles">
					{{ page }}
				</a>
			</li>

			<li v-if="pager.totalPages > (pager.currentPage + maxPages)"
				class="page-item p-1 py-2 disabled"
				:style="liStyles">
				<img :src="require('@/assets/icons/dots-horz.svg')" alt="..." class="mt-3" width="12" height="12">
			</li>
			<li v-if="pager.totalPages > (pager.currentPage + maxPages)"
				class="page-item"
				:class="{'active': pager.currentPage === pager.totalPages}"
				:style="liStyles">
				<a href="javascript:{}"
				   class="page-link"
				   @click="goToPage(pager.totalPages)"
				   :style="aStyles">
					{{ pager.totalPages }}
				</a>
			</li>

			<li class="page-item next"
				:class="{'disabled': pager.currentPage === pager.totalPages}"
				:style="liStyles">
				<a v-if="labels.next"
				   href="javascript:{}"
				   class="page-link"
				   @click="goToPage(pager.currentPage + 1)"
				   :style="aStyles">
					{{ labels.next }}
				</a>
				<a v-else href="javascript:{}"
				   class="page-link"
				   @click="goToPage(pager.currentPage + 1)"
				   :style="aStyles">
					<img :src="require('@/assets/icons/arrow-right.svg')" alt="Вперёд" width="12" height="12">
				</a>
			</li>
			<li v-if="labels.last"
				class="page-item last"
				:class="{'disabled': pager.currentPage === pager.totalPages}"
				:style="liStyles">
				<a href="javascript:{}"
				   class="page-link"
				   @click="goToPage(pager.totalPages)"
				   :style="aStyles">
					{{ labels.last }}
				</a>
			</li>
		</ul>
	</nav>
</template>

<script>
import paginate from 'jw-paginate';
import CommonService from "@/services/CommonService";

const defaultLabels = {
	first: 'First',
	last: 'Last',
	previous: 'Previous',
	next: 'Next'
};

const defaultStyles = {
	ul: {
		margin: 0,
		padding: 0,
		display: 'inline-block'
	},
	li: {
		listStyle: 'none',
		display: 'inline',
		textAlign: 'center'
	},
	a: {
		cursor: 'pointer',
		padding: '6px 12px',
		display: 'block',
		float: 'left'
	}
};

export default {
	name: "Pagination",
	props: {
		itemsList: {
			type: Array,
			required: true
		},
		initialPage: {
			type: Number,
			default: 1
		},
		pageSize: {
			type: Number,
			default: 10
		},
		maxPages: {
			type: Number,
			default: 10
		},
		labels: {
			type: Object,
			default: () => defaultLabels
		},
		styles: {
			type: Object
		},
		disableDefaultStyles: {
			type: Boolean,
			default: false
		}
	},
	data () {
		return {
			page: 0,
			pager: {},
			ulStyles: {},
			liStyles: {},
			aStyles: {}
		}
	},
	methods: {
		isEmpty(data) {
			return CommonService.isEmpty(data);
		},
		goToPage(page) {
			console.log(page);
			return this.setPage(page, true);
		},
    updateProps(pagination) {
      this.$props.itemsList = pagination.items;
    },
		setPage(page, fire) {
			this.page = page;
			const { items, pageSize, maxPages } = this;

			// get new pager object for specified page
			const pager = paginate(items.length, page, pageSize, maxPages);

			// get new page of items from items array
			const pageOfItems = items.slice(pager.startIndex, pager.endIndex + 1);

			// update pager
			this.pager = pager;

			// emit change page event to parent component
			if (fire) {
				this.$emit('changePage', this.page);
				/*this.$nextTick(() => {
					window.scrollTo({
						top: offset,
						behavior: "smooth"
					});
				});*/
				this.$root.$refs.root.scrollIntoView({
					behavior: 'smooth',
					block: 'start',
					scrollBehavior: 'smooth'
				});
			}
		}
	},
	mounted () {
		/*if (!this.$listeners.changePage) {
			throw 'Missing required event listener: "changePage"';
		}*/

		if (!this.disableDefaultStyles) {
			this.ulStyles = defaultStyles.ul;
			this.liStyles = defaultStyles.li;
			this.aStyles = defaultStyles.a;
		}

		if (this.styles) {
			this.ulStyles = { ...this.ulStyles, ...this.styles.ul };
			this.liStyles = { ...this.liStyles, ...this.styles.li };
			this.aStyles = { ...this.aStyles, ...this.styles.a };
		}

		if (!this.isEmpty(this.items)) {
			this.setPage(this.initialPage);
		}
	},
	watch: {
		pager: function() {
			return this.pager;
		},
	},
	computed: {
		items() {
			return this.$props.itemsList ?? []
		}
	}
}
</script>